import "./scss/main.scss";

//import 'bootstrap';
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
//import 'bootstrap/js/dist/tooltip';

$(function () {
    if ($(".alert").length) {
        $("html, body").animate(
            {
                scrollTop: $(".alert").offset().top,
            },
            1000
        );
    }
});
